import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  Button,
  Hero,
  IconBox,
  SectionHeader,
  TeamCard,
  Link,
} from "../components/common";

import Signature from "../assets/images/vectors/signature.svg";
import Group from "../assets/images/vectors/group.svg";
import Target from "../assets/images/vectors/target.svg";
import Pen from "../assets/images/vectors/pen.svg";
import Here from "../assets/images/vectors/here.svg";
import Splash from "../assets/images/vectors/spalsh.svg";
import pencilPaper from "../assets/images/vectors/pencil-paper.svg";
import photoshop from "../assets/images/vectors/photoshop.svg";
import cinema4d from "../assets/images/vectors/cinema4d.svg";
import illustrator from "../assets/images/vectors/illustrator.svg";
import figma from "../assets/images/vectors/figma.svg";
import afterEffects from "../assets/images/vectors/after-effects.svg";
import affinity from "../assets/images/vectors/affinity.svg";
import lightroom from "../assets/images/vectors/lightroom.svg";
import worldMap from "../assets/images/vectors/world-map.svg";
import facebook from "../assets/images/vectors/facebook.svg";
import twitter from "../assets/images/vectors/twitter.svg";
import pinterest from "../assets/images/vectors/pinterest.svg";
import behance from "../assets/images/vectors/behance.svg";
import dribbble from "../assets/images/vectors/dribbble.svg";
import instagram from "../assets/images/vectors/instagram.svg";

import mapPin from "../assets/icons/map-pin.svg";

import "./about.css";
import { getStaticSEOImage } from "../helpers";

function AboutPage({ location }) {
  return (
    <Layout>
      <SEO
        path={location?.pathname}
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="About"
        image={getStaticSEOImage("about")}
      />

      <section className="mt-24 mb-24">
        <Hero
          subTitle="About Us"
          title="A Small Creative Team to Support Small & Midsize Business"
        />
      </section>
      <section className="mb-32">
        <blockquote className="dev-vg-about__blockquote bg-background-white body-large relative m-auto rounded-3xl text-ui-gray-plus-4 leading-8">
          <div
            style={{
              width: 48,
              height: 48,
              top: -24,
              left: "50%",
              transform: "translate(-50%)",
            }}
            className="flex items-center justify-center bg-brand-primary text-background-white rounded-full absolute"
          >
            <i className="vg-quote text-background-white" />
          </div>
          <p className="mb-8">
            I started from a village, where no one knows how to design things,
            or how to solve a problem with design. From April 2008, I started my
            journey. I didn’t had a computer for the first year. But I didn’t
            stopped, I bought books, learned, made projects, failed and then
            tried again.
          </p>
          <p className="mb-8">
            Since 2011, I started selling my products on GraphicRiver (Envato’s
            sister concern) under the name <a href="#">Shemul</a> and never
            looked back. I continuously made products for the people to step up
            their business and projects.
          </p>
          <p className="mb-8">
            But, I was a single man army. I always wanted to make more useful,
            exclusive, bigger products to solve more problems, to make a better
            business for my customers, to provide bigger value with less
            spendings and then I found my peoples have the same journey as mine.
          </p>
          <p className="mb-8">
            We are dedicated to create valuable, eye catching, ready to use
            products for you. If you need anything specific{" "}
            <Link to="/contact">please let us know.</Link>
            We would be happy to create that for you.
          </p>
          <div className="mb-6">
            <img alt="signature" src={Signature} />
          </div>
          <p>
            <span className="font-bold text-ui-gray-plus-4">Shahadat,</span>
            <br />{" "}
            <span className="text-ui-gray-plus-3">Founder, Vackground</span>
          </p>
        </blockquote>
      </section>
      <section className="mb-48">
        <div className="text-center mb-12">
          <SectionHeader
            art
            artVector={Group}
            title="Our Hearts & Pearls"
            description="With over 12+ years of digital product selling experience, ‘I’ become ‘we’, an individual designer to a small team of passionate designers, developers and other creative people. Some of us works in house, others are working as remote or freelance."
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 px-16">
          <TeamCard name="Shahadat Rahman" title="Founder, Designer" />
          <TeamCard name="Zonayed Ahmed" title="Web Developer" />
          <TeamCard name="Sujon Ahmed" title="Illustrator" />
          <TeamCard name="Raj Sikder" title="Social Media Manager" />
          <TeamCard name="Israt Jahan" title="Illustrator" />
          <TeamCard name="Nazmul Hossain" title="Content Manager" />
        </div>
      </section>
      <section className="mb-32">
        <div className="text-center mb-24">
          <SectionHeader
            art
            artVector={Target}
            title="We’re On A Serious Mission"
            description={
              <>
                Our goal is to create vector backgrounds and surface patterns
                organically and geometrically. We provide free version of all of
                our products to kickstart your business and other personal
                projects, along with{" "}
                <Link to="/refund">60 days money back guarantee</Link>, this is
                why we are different.
              </>
            }
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <IconBox
            icon="vg-gift"
            title={
              <>
                Provide <Link to="/products">Invaluable</Link> Products
              </>
            }
          />
          <IconBox
            icon="vg-dollar-sign"
            title={
              <>
                Make Your <Link to="/faq">Business</Link> Grow Fast
              </>
            }
          />
          <IconBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-check"
            title={
              <>
                Peace of Mind <Link to="/refund">(No Risk)</Link> For 60 Days
              </>
            }
          />
        </div>
      </section>
      <section className="mb-40">
        <div className="text-center mb-16">
          <SectionHeader art artVector={Pen} title="Our Tools & Techniques" />
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-8 mb-16">
          <div className="text-center">
            <img className="mx-auto" alt="Pencil & Paper" src={pencilPaper} />
            <p className="body-large mt-8">Pencil & Paper</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="Photoshop" src={photoshop} />
            <p className="body-large mt-8">Photoshop</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="Cinema 4D" src={cinema4d} />
            <p className="body-large mt-8">Cinema 4D</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="Illustrator" src={illustrator} />
            <p className="body-large mt-8">Illustrator</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="Figma" src={figma} />
            <p className="body-large mt-8">Figma</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="After Effects" src={afterEffects} />
            <p className="body-large mt-8">After Effects</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="Affinity" src={affinity} />
            <p className="body-large mt-8">Affinity</p>
          </div>
          <div className="text-center">
            <img className="mx-auto" alt="Lightroom" src={lightroom} />
            <p className="body-large mt-8">Lightroom</p>
          </div>
        </div>
        <div className="dev-vg-about__tools__desc body-large text-center max-w-4xl m-auto">
          And a lot of plugins, add ons, web based tools to create great
          products for you. We have years of experience with these programs and
          use them in a different ways. For example, we render images from
          Cinema 4D and manually vectorize them in Adobe Illustrator.
        </div>
      </section>
      <section className="relative mb-32">
        <div className="absolute top-1 z-0">
          <img className="opacity-75" src={worldMap} alt="World Map" />
          <div className="dev-vg-country-pinting-arrow absolute animate-bounceX">
            <img src={Here} alt="Map Pin" />
          </div>
        </div>
        <div className="pt-64 relative z-10 max-w-xl mx-auto">
          <div className="dev-vg-about__location__pin mb-4">
            <img alt="map pin" src={mapPin} />
          </div>
          <h2
            className="pb-16 font-bold leading-14"
            style={{ letterSpacing: "-2px" }}
          >
            We Are From Beautiful{" "}
            <strong className="text-brand-primary">Bangladesh</strong>
          </h2>
          <div>
            <p className="mb-8 text-ui-gray-plus-4 leading-8 tracking-tightsy font-normal text-lg">
              The green country with beautiful people. Please do a visit in
              Cox’s Bazar or Sundarban. And don’t forget to visit our office
              also at -
            </p>
            <p className="text-ui-gray-plus-4 leading-8 tracking-tightsy font-normal text-lg">
              Vackground Ltd, 403-01, Daulatgonj Bazar, Laksam Cumilla,
              Bangladesh - 3570
            </p>
          </div>
          <div className="mt-16 flex gap-x-8">
            <div>
              <a href="https://facebook.com/vackground/">
                <img alt="Facebook" src={facebook} />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/vackground">
                <img alt="Twitter" src={twitter} />
              </a>
            </div>
            <div>
              <a href="https://www.pinterest.com/vackground/">
                <img alt="Pinterest" src={pinterest} />
              </a>
            </div>
            <div>
              <a href="https://www.behance.net/vackground">
                <img alt="Behance" src={behance} />
              </a>
            </div>
            <div>
              <a href="https://dribbble.com/hishahadat">
                <img alt="Dribbble" src={dribbble} />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/vackgroundhq/">
                <img alt="Instagram" src={instagram} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-24">
        <div className="mb-16">
          <SectionHeader
            art
            artVector={Splash}
            title={
              <>
                Show, Don’t Tell
                <br />
                Grow Your Business Visually!
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <Button
            to="/products"
            hasMoustache
            icon="vg-arrow-right"
            text="Shop Now"
          />
        </div>
      </section>
    </Layout>
  );
}

AboutPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default AboutPage;
